.uk-timeline .uk-timeline-item .uk-card {
	max-height: 300px;
}

.uk-timeline .uk-timeline-item {
    display: flex;
    position: relative;
}

.uk-timeline .uk-timeline-item::before {
    background: #dadee4;
    content: "";
    height: 100%;
    left: 19px;
    position: absolute;
    top: 20px;
    width: 2px;
	z-index: -1;
}

.uk-timeline .uk-timeline-item .uk-timeline-icon .uk-badge {
		margin-top: 20px;
    width: 40px;
    height: 40px;
}

.uk-timeline .uk-timeline-item .uk-timeline-content {
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    padding: 0 0 0 1rem;
}