.VcBg{
    background-image: url('Imgage/ModelBg.jpeg');
    width: 100%;
    height: 100vh;
    background-repeat: no-repeat;
    background-size: cover;
}
.VcP1{
    font-size: 28px;
    font-weight: 500;
    color: white;
    margin-top: 60%;
}
.VcP2{
    font-size: 16px;
    font-weight: 500;
    color: white;
}
.VcP3{
    font-size: 16px;
    font-weight: 500;
    color: white;
}
.VcBtn{
    font-size: 16px;
    font-weight: 500;
    color: blue;
    border-radius: 25px;
    border: none;
    width: 25vw;
    height: 5vh;
}
.VcP4{
    font-size: 16px;
    font-weight: 500;
    color: rgb(1, 1, 122);
}
.VcP5{
    font-size: 13px;
    font-weight: 500;
    color: rgb(1, 1, 122);
    line-height: 15px;
}
.VcP6{
    font-size: 13px;
    font-weight: 500;
    color: rgb(1, 1, 122);
    line-height: 0px;

}
.VcP7{
    font-size: 13px;
    font-weight: 500;
    color: rgb(1, 1, 122);
    line-height: 0px;
}


