
footer {
    border-radius: 10px 10px 0px 0px;
    text-align: center;
    display: flex;
    justify-content: space-around;
    font-size: 24px;
    background: #db5e34;
    /*padding: 6px;
    */color: #ffffff;
    border-top: 1px solid whitesmoke;
    box-shadow: 0px -2px 1px #fff;
    z-index: 999;
    position: fixed bottom;
    bottom: 0%;
    width: 100%;
    overflow: hidden;
    -webkit-border-radius: 10px 10px 0px 0px;
    -moz-border-radius: 10px 10px 0px 0px;
    -ms-border-radius: 10px 10px 0px 0px;
    -o-border-radius: 10px 10px 0px 0px;
}

footer>a {
    position: relative;
    z-index: 50;
    cursor: pointer;
    color: #fff;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    line-height: 40px;
    transition: all 0.5s ease;
    font-size: 35px;
}

footer>a:hover {
    transition: all 0.5s ease;
    color: #1b689c;
    box-shadow: 0px 0px 8px #1f6fa5;
}

.fa-navicon {
    position: absolute;
    background: #00b4ff;
    padding: 10px;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    line-height: 50px;
    color: #fff;
    top: -35px;
}

.nav-open {
    position: absolute;
    width: 200px;
    background: #ccc;
    border-radius: 100%;
    left: 0;
    right: 0;
    margin: auto;
    bottom: 0;
}

.circle_nav {
    position: fixed;
    bottom: 59px;
    right: 0;
    left: 0;
    display: block;
    width: 26em;
    height: 26em;
    font: 500 14px/14px arial normal;
    margin: auto;
    z-index: 10;
    overflow: hidden;
}

.circle_nav .hamburger {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2;
    width: 60px;
    height: 60px;
    border-radius: 100%;
    background: #db5e34;
    color: rgb(0, 0, 0);
    text-align: center;
    box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.44);
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    margin: auto;
    line-height: 70px;
    margin-bottom: 60px;
}

.circle_nav .hamburger i {
    font-size: 22px;
}

.circle_nav .hamburger:active,
.circle_nav .hamburger:hover {
    box-shadow: 0px 4px 7px 0px rgba(0, 0, 0, 0.44);
    background: #bf4f29;
}

.circle_nav a {
    color: white;
    text-decoration: none;
}

.circle_nav.active .hamburger {
    box-shadow: 0px 4px 7px 0px rgba(0, 0, 0, 0.44);
    background: #bf4f29;
}

.circle_nav.active .menu {
    pointer-events: auto;
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
}

.menu {
    position: absolute;
    top: 50%;
    right: 8%;
    z-index: 0;
    width: 300px;
    height: 300px;
    -webkit-transform: scale(0.1);
    -ms-transform: scale(0.1);
    -moz-transform: scale(0.1);
    transform: scale(0.1);
    pointer-events: none;
    -webkit-transition: all .15s ease;
    -moz-transition: all .15s ease;
    transition: all .15s ease;
    -moz-box-sizing: content-box;
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
}

.menu>li:nth-of-type(1) {
    -moz-transform: rotate(0deg) skew(50deg);
    -ms-transform: rotate(0deg) skew(50deg);
    -webkit-transform: rotate(0deg) skew(50deg);
    transform: rotate(0deg) skew(50deg);
}

.menu>li:nth-of-type(2) {
    -moz-transform: rotate(35deg) skew(50deg);
    -ms-transform: rotate(35deg) skew(50deg);
    -webkit-transform: rotate(35deg) skew(50deg);
    transform: rotate(35deg) skew(50deg);
}

.menu>li:nth-of-type(2)>a,
.menu>li:nth-of-type(4)>a {
    background-color: #d85c32;
}

.menu>li:nth-of-type(3) {
    -moz-transform: rotate(70deg) skew(50deg);
    -ms-transform: rotate(70deg) skew(50deg);
    -webkit-transform: rotate(70deg) skew(50deg);
    transform: rotate(70deg) skew(50deg);
}

.menu>li:nth-of-type(4) {
    -moz-transform: rotate(105deg) skew(50deg);
    -ms-transform: rotate(105deg) skew(50deg);
    -webkit-transform: rotate(105deg) skew(50deg);
    transform: rotate(105deg) skew(50deg);
}

.menu>li:nth-of-type(5) {
    -moz-transform: rotate(140deg) skew(50deg);
    -ms-transform: rotate(140deg) skew(50deg);
    -webkit-transform: rotate(140deg) skew(50deg);
    transform: rotate(140deg) skew(50deg);
}

.menu>li {
    position: absolute;
    bottom: 50%;
    right: 50%;
    font-size: 1.5em;
    width: 10em;
    height: 10em;
    -webkit-transform-origin: 100% 100%;
    -moz-transform-origin: 100% 100%;
    -ms-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
    overflow: hidden;
    margin-top: -1.3em;
    margin-left: -10em;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    transition: all 0.3s ease;
}

.menu>li a {
    position: absolute;
    bottom: -7.25em;
    right: -7.25em;
    display: block;
    height: 14.5em;
    width: 14.5em;
    border-radius: 50%;
    text-decoration: none;
    color: #fff;
    padding-top: 0em;
    text-align: center;
    font-size: 1.18em;
    -webkit-transform: skew(-50deg) rotate(-70deg) scale(1);
    -ms-transform: skew(-50deg) rotate(-70deg) scale(1);
    -moz-transform: skew(-50deg) rotate(-70deg) scale(1);
    transform: skew(-50deg) rotate(-70deg) scale(1);
    -webkit-backface-visibility: hidden;
    -webkit-transition: opacity 0.3s, color 0.3s;
    -moz-transition: opacity 0.3s, color 0.3s;
    transition: opacity 0.3s, color 0.3s;
    background-color: #ec693d;
}

.menu>li a i,
.submenu>li a i {
    padding: 20px 15px 0 0;
}

.menu>li a:active,
.menu>li a:hover,
.submenu>li a:active,
.submenu>li a:hover {
    background-color: #bf4f29;
}

li,
ul {
    display: block;
    margin: 0;
    padding: 0;
}