.OrderIcon1{
    width: 100px;
    height: 60px;
    padding: 15px 15px 15px 15px;
    border: 2px solid #D1D1D1D1;
    background-color: white;
    border-right: none;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
}
.OrderInput{
    font-family: inter;
    font-size: 19px;
    font-weight: 400;
    width:100% !important;
    height: 60px;
    border: 2px solid #D1D1D1D1;
    border-left: none;
    outline: none;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
}
.OrderIcon2{
color: white;
font-size: 20px;

}
.OrderPp1{
    color: white;
    font-size: 14px;
}
.OrderPp2{
    color: white;
    line-height: 0px;
    font-size: 18px;
}
.OrderIcon3{
    color: white;
    font-size: 20px;
}
.OrderPp3{
    font-size: 14px;
    color: white;
}
.OrderPp4{
    color: white;
    line-height: 0px;
    font-size: 18px;

}
.OrderP1 {
    font-size: roboto;
    font-size: 32px;
    font-weight: 500;
    color: #677079;
    text-align: center;
}
.OrderCard{
    display: block;
    margin-left: auto;
    margin-right: auto;
}
.OrderBtnAll{
    padding: 10px 30px 10px 30px;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}
.OrderBtnPending{
    padding: 10px 30px 10px 30px;
    border-radius: 10px;
}
.OrderBtnComplete{
    padding: 10px 30px 10px 30px;
    border-radius: 10px;
}